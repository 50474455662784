//---SPACERS---///

.spacer-1x{
  height: $size-1x;
}

.spacer-2x{
  height: $size-2x;
}

.spacer-4x{
  height: $size-4x;
}

.spacer-6x{
  height: $size-6x;
}

.spacer-8x{
  height: $size-8x;
}

.spacer-10x{
  height: $size-10x;
}

.spacer-12x{
  height: $size-12x;
}

.spacer-16x{
  height: $size-16x;
}

.spacer-24x{
  height: $size-24x;
}

.justify-space-between{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.show-on-mobile{
  @media screen and (min-width: $screen-xs) {
    display: none;
  }
  @media screen and (max-width: $screen-xs) {
    display: block;
  }
}







  // @media (max-width: $screen-sm) {
  //   display:none;
  // }
