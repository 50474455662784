#fader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
    background: $bg-color;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
}

@keyframes fade-out {
    from { opacity: 1 }
      to { opacity: 0 }
}

@keyframes fade-in {
    from { opacity: 0 }
      to { opacity: 1 }
}

#fader.fade-out {
    opacity: 0;
    animation-name: fade-out;
}

#fader.fade-in {
    opacity: 1;
    animation-name: fade-in;
}
