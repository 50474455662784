@font-face {
    font-family: 'Graphik';
    // font-display: swap;
    src: url('/assets/fonts/Graphik-Regular.eot');
    src: url('/assets/fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Graphik-Regular.woff2') format('woff2'),
        url('/assets/fonts/Graphik-Regular.woff') format('woff'),
        url('/assets/fonts/Graphik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
    font-display: swap;

}

@font-face {
    font-family: 'Graphik';
    // font-display: swap;
    src: url('/assets/fonts/Graphik-Medium.eot');
    src: url('/assets/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Graphik-Medium.woff2') format('woff2'),
        url('/assets/fonts/Graphik-Medium.woff') format('woff'),
        url('/assets/fonts/Graphik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    unicode-range: U+000-5FF;
    font-display: swap;

}


@font-face {
    font-family: 'Kalam';
    src: url('/assets/fonts/Kalam-Bold.eot');
    src: url('/assets/fonts/Kalam-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Kalam-Bold.woff2') format('woff2'),
        url('/assets/fonts/Kalam-Bold.woff') format('woff'),
        url('/assets/fonts/Kalam-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kalam';
    src: url('/assets/fonts/Kalam-Regular.eot');
    src: url('/assets/fonts/Kalam-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Kalam-Regular.woff2') format('woff2'),
        url('/assets/fonts/Kalam-Regular.woff') format('woff'),
        url('/assets/fonts/Kalam-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Henrietta';
    src: url('/assets/fonts/VCHenrietta-SemiBold.eot');
    src: url('/assets/fonts/VCHenrietta-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/VCHenrietta-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/VCHenrietta-SemiBold.woff') format('woff'),
        url('/assets/fonts/VCHenrietta-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Henrietta';
    src: url('/assets/fonts/VCHenrietta-Medium.eot');
    src: url('VCHenrietta-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/VCHenrietta-Medium.woff2') format('woff2'),
        url('/assets/fonts/VCHenrietta-Medium.woff') format('woff'),
        url('/assets/fonts/VCHenrietta-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
