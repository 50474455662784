/**
 * THE BULK OF IT ALL
 */
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;

  @media (max-width: $screen-sm) {
    // font-size: 10px;
  }
}

body {
  overflow-x: hidden;
  background: $bg-color;
  font-family: $body-font, sans-serif;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  @include highlight-color-dark;
  color:#f7f4dd;

  // @include fadeContentIn(5s);
}

code,
kbd,
pre,
samp {
  font-family: $monospace, monospace;
  font-size: 1em;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

*::selection {
  background: gray;
}

img {
  border-radius: $border-radius-standard;
  width: 100%;
  display: block;
}

hr {
  height: 1px;
  background-color: $divider;
  border: none;
}

video {
  display: block;
  border-radius: $border-radius-standard;
}


// BUTTONS //////////
button {
  @include primary-button;
}

button.secondary-button{
  @include secondary-button;
}

button.small-button{
  @include small-button;
}

.button-group{
  display: flex;
  gap: $size-2x;
  align-items: center;
  justify-content: center;


  @media screen and (max-width: 1000px) {

    // flex-direction: column;
    // align-items: center;
    justify-content: center;
  }



}

.button-group.hide-on-mobile{
  @media screen and (max-width: $screen-xs){
    display: none;
  }
}



.button-group-navigation{
  display: flex;
  gap: $size-2x;
  align-items: center;

  @media screen and (max-width: 1000px) {

    // flex-direction: column;
    // align-items: center;


    justify-content: center;
  }

}
// END BUTTONS //////


.content {
  @include margin-0-auto;
  padding: $size-12x 0;
  border-radius: $border-radius-standard;

  // @include highlight-color-light;

}



body.disable-scroll {
  overflow: hidden;
}


// FORM CONTROLS
.input-group{
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: $size-2x;

  button{
    border-radius: $border-radius-standard;
  }
}

input{
  border: none;
  padding: $size-2x;
  border-radius: $border-radius-standard;
  background: $bg-gray-lighter;

  &:focus{
    outline: -webkit-focus-ring-color auto 0px;
    outline-color: blue;
    outline-style: auto;
    outline-width: 0px;

    border: 1px solid $green;
    caret-color: $green;
    color:white;
  }
}
/////////////////////////////

body{
  // @include standard-width;
  margin: 0 auto;
}

.full-width-bg{
  background:$black;
}

.full-width-bg-green{
  background: $green;
}

.about-interview-kit{
  @include smaller-width;
  padding: $size-24x 0;

  @media screen and (max-width: $screen-xs){
    padding: $size-12x 0;

    // text-align: center;
  }



}






.hero-text{
  h1{
    margin-top: 0px;
  }
}

.hero-animation{
  background-color: #1a1e25;
  height: 100vh;
  @include scaleContentIn (1000ms, .9);
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    padding: 0 48px;
    animation: hover 2s infinite ease;
  }
}

.body-section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 94px;
  justify-content: center;
  align-items: center;
  margin-bottom:94px;

}

.icon-large{
  width:64px;
  height: 64px;
}

.content-preview-wrapper{
  @include standard-width;
  text-align: center;
  background-color: #14151D;
  // color: $off-white;
  border-radius: 16px;
  padding: $size-12x;
  margin-top: $size-12x * 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $size-12x;
  // border: 1px solid $gray-500;

  @media (max-width: $screen-xs) {
    padding: $size-6x;
    margin: $size-24x 0 0 0;
    width: 100%;
  }
}

.content-preview-header{
  display: flex;
  gap: $size-12x;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chapter{
  img{
    padding-bottom: $size-8x;
  }
}

.chapter-preview{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $size-8x;
  row-gap: $size-12x;
  text-align: left;

  @media (max-width: $screen-sm) {
    grid-template-columns: 1fr;
  }

  ul{
    padding-left:0;
  }

  li{
    font-size: 1.2rem;
    list-style: none;
    &:before{
      content: "→ ";
    }
  }
}


.purchase-cta{
  text-align: center;
  background-color: white;
  border-radius: $border-radius-standard;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: $size-12x;

  display: flex;
  gap: $size-12x;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: $size-12x;

  .header{

  }

  .button-guarantee{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $size-4x;

    p{
      color:$gray-500;

      span{
        border-bottom:1px solid $gray-500;
        padding-bottom: $size-1x;
        font-weight: 500;

      }
    }
  }

}

.image-large{
  max-width: $content-wide;
  margin: 0 auto;
}

.horizontal-margin{

  margin: 0 $size-12x;

  @media screen and (max-width: $screen-xs){
    margin: 0 $size-6x;
  }
}

.emphasis-underline{
  color: $yellow;
  // border-bottom: 4px dotted $yellow;
  text-decoration: none;
  font-family:'Kalam';
  word-spacing: -.5rem;
}


.columns{

  @include standard-width;
  display:flex;
  gap: 12px;
  position: relative;
  overflow: hidden;
  width: 100%;

  .column{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}


.icon-starburst{
  width:128px;
  text-align: center;
  display: inline-block;
  margin-top: -108px;
  // animation: rotate 10s infinite linear;
}



@keyframes rotate {  /* define the "rotate" animation */
  from {
    transform: rotate(0deg);  /* start at 0 degrees */
  }
  to {
    transform: rotate(360deg);  /* end at 360 degrees (full rotation) */
  }
}

@keyframes hover {  /* define the "hover" animation */
  0% {
    transform: translateY(0);  /* start at the original position */
  }

  50% {
    transform: translateY(-10px);  /* end 5 pixels above the original position */
  }

  100% {
    transform: translateY(0);  /* end 5 pixels above the original position */
  }
}


@keyframes jerkrotate {  /* define the "rotate" animation */
  0% {
    transform: rotate(-15deg);  /* start at 0 degrees */
  }
  50% {
    transform: rotate(15deg);  /* end at 360 degrees (full rotation) */
  }
  100% {
    transform: rotate(-15deg);  /* start at 0 degrees */
  }
}



#mobile-scroll-hint{
  padding-top: $size-24x;
}


#scroll-hint{
  cursor:default;

  @media screen and (max-width: $screen-md){
    border-top: 1px solid $gray-700;
    border-left: 1px solid $gray-700;
    border-right: 1px solid $gray-700;

  }

  @media screen and (max-width: $screen-xs){
    opacity: 0 !important;
  }



  opacity: 0;
  animation: slideUp 1s linear forwards 1s;
  animation-timing-function: ease-in-out;

//   -webkit-box-shadow:0px 0px 23px 0px rgba(255,255,255,0.52);
// -moz-box-shadow: 0px 0px 23px 0px rgba(255,255,255,0.52);
// box-shadow: 0px 0px 23px 0px rgba(255,255,255,0.52);


  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(60px);
    }


    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  width:100%;
  max-width:600px;
  padding: 12px 24px;
  text-align: center;
  background: $gray-800;
  border-radius: 24px 24px 0 0;

  #icon{
    animation: jerkrotate 2s infinite ease;
    color: $yellow;
    display: inline-block;

  }

}



#canvas > canvas{
max-width:100%;
height: 100%;
}


.container {
  display: flex;
  height: 100vh;
  border-bottom: 2px solid #14151E;
}

.column {
  width: 50%;
}

.column-wrapper{
  max-width:600px;
  display:flex;
  flex-direction: column;
  gap: $size-24x;


}

// @media screen and (max-width: 1000px) {
  .right {
    position:absolute;
    top:0;
    width:100vw;
    height:100vh;
    z-index:-10;
  }

  .left{
    width:100%;
    background-color: #080c14e3 !important;
    padding: $size-10x $size-4x 0 $size-4x !important;
    text-align: center;
    align-items: center;
    justify-content: center;

  }

  .column{
    width:100%;

  }
// }

.left {
  background-color: $black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  // justify-content: center;
  padding: $size-12x $size-12x 0 $size-12x;

}

.right {
  //change this to debug canvas width
  background-color: #14151E;
}


.two-column{
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include smaller-width;
  padding: 0 0 64px 0;
  grid-gap: $size-12x;

  // p{
  //   font-size: 1.5rem;
  // }

  @media (max-width: $screen-sm) {
    grid-template-columns: 1fr;

  }

}

.two-column + .two-column {
  padding: 0 0 $size-24x 0;

}

.icon-small{
  width:$size-12x;
  padding-bottom: $size-4x;

  @media screen and (max-width: $screen-xs) {
    // width:$size-12x;

  }
}

.divider{
  @include smaller-width;
  opacity:.25;
}

.note{
  border-radius: 12px 12px 0 0;
  background: $off-white;
  color: $black;
  @include smaller-width;
  padding: 64px;

  @media screen and(max-width: $screen-sm){
    padding: 48px;
  }

  @media screen and(max-width: $screen-xs){
    padding: 24px;

    p{
      font-size:1.1rem !important;

    }
    margin: 0 $size-2x 120px $size-2x;
    border-radius: $size-3x;

  }


  p{
    // font-family: "Kalam";
    font-size:1.2rem;

    a{
      color: inherit;
      border-bottom: 1px solid $black;
    }
  }


  img{
    width: 60px;
    padding-bottom: $size-4x;
  }

  .intro{
    display: flex;
    gap: $size-6x;
    align-items: center;
    padding-bottom: $size-8x;

  }
}

.inline-CTA{
  @include smaller-width;
  padding: $size-24x 0;
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img{
    max-width: 600px;
    padding-bottom: $size-8x;
  }

}


.bottom-bar{
  display: none;

  @media screen and (max-width: $screen-xs){
    display:block;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(8, 12, 20, .88);

  // border-top: 1px solid gray;
  padding: $size-4x 0;
  // margin: $size-4x;
  // border-radius: 24px;


  border: 1px solid #474747cc;
  box-shadow: 0px 1px 58px 0px hsla(0, 0%, 0%, 0.06);
  backdrop-filter: saturate(180%) blur(20px);

  button{
    font-size: 1.2rem;
  }
}

.small{
  font-size: 1.2rem;
  opacity: .8;
}

