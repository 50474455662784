nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: rgba(8, 12, 20, .88);
  z-index: 999;
  transform: translateY(-100%);
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.79,0.14,0.15,0.86);
  padding: $size-2x 0;
  border-bottom: 1px solid #474747cc;
  box-shadow: 0px 1px 58px 0px hsla(0, 0%, 0%, 0.06);
  backdrop-filter: saturate(180%) blur(20px);
  margin: 0 auto;
  // width: 100%;


  @media screen and (max-width: $screen-xs){
    display:none;
  }

}

.nav-container {
  // max-width: 1200px;

  margin: 0 auto;
  // padding: 0 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
  max-width: $content-wide;

  // @media (max-width: $screen-sm) {
  //   padding: 0 24px;
  //
  // }

  @media screen and (max-width: $screen-lg){
    padding: 0 24px;

  }
}

.logo {
  img{
    height: 16px;
    width: auto;
    border-radius: 0px;
  }

  display: flex;
  gap: $size-2x;
  max-height: 16px;
}

.logo-large{
  img{
    // height: 36px;
    width: auto;
    border-radius: 0px;
  }

  display: flex;
  gap: $size-2x;
  height: 24px;
}

nav.visible {
  transform: translateY(0);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#compass {
  display:inline-block;
  animation: rotate infinite 4s both;
  // animation-timing-function: cubic-bezier(.22, .61, .36, 1);

}
